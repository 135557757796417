import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="new-category-modal"
export default class extends Controller {
    static targets = ["nameInput"]

    connect() {
        this.element.addEventListener("shown.bs.modal", () => this.focusNameInput())
    }

    focusNameInput() {
        this.nameInputTarget.focus()
    }
}
