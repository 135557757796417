import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="scroll-to-top"
export default class extends Controller {
  connect() {
    this.element.addEventListener("click", () => {
      window.scrollTo({ top: 0, behavior: "smooth" })
    })
    window.addEventListener("scroll", () => {
      if (window.scrollY > 100) {
        this.element.classList.remove("d-none")
      } else {
        this.element.classList.add("d-none")
      }
    })
  }
}
