// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"
import "./custom_events/dispatch_event"
import "./lib/medium_zoom"
import * as bootstrap from "bootstrap"
// import BugsnagPerformance from "@bugsnag/browser-performance";

window.bootstrap = bootstrap

const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl))

if (navigator.serviceWorker) {
    navigator.serviceWorker
             .register("/service-worker.js", { scope: "/"})
             .then(() => navigator.serviceWorker.ready)
             .then((registration) => {
                if ("SyncManager" in window) {
                    registration.sync.register("sync-forms");
                } else {
                    console.log("This browser does not support background sync.")
                }
             }).then(() => console.log("[Mini Geek]", "Service worker registered!"))

}
//
// BugsnagPerformance.start({
//     apiKey: '6f19e3af046c08446dced9e449a86bb8',
// })
