import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="admin"
const SIDEBAR_COLLAPSED_CLASS = "sidebar__collapsed"
const SIDEBAR_STATE_KEY = "sidebarState"
const SIDEBAR_STATE_COLLAPSED = "collapsed"
const SIDEBAR_STATE_EXPANDED = "expanded"


export default class extends Controller {
    static targets = ["sidebar"]

    connect() {
        if (this.rememberedSidebarState === SIDEBAR_STATE_COLLAPSED) {
            this.collapseSidebar()
        } else {
            this.expandSidebar()
        }
    }

    toggleSidebar() {
        if (this.sidebarTarget.classList.contains(SIDEBAR_COLLAPSED_CLASS)) {
            this.expandSidebar()
        } else {
            this.collapseSidebar()
        }
    }

    collapseSidebar() {
        this.sidebarTarget.classList.add(SIDEBAR_COLLAPSED_CLASS)
        this.rememberSidebarCollapsed()
    }

    expandSidebar() {
        this.sidebarTarget.classList.remove(SIDEBAR_COLLAPSED_CLASS)
        this.rememberSidebarExpanded()
    }

    rememberSidebarCollapsed() {
        localStorage.setItem(SIDEBAR_STATE_KEY, SIDEBAR_STATE_COLLAPSED)
    }

    rememberSidebarExpanded() {
        localStorage.setItem(SIDEBAR_STATE_KEY, SIDEBAR_STATE_EXPANDED)
    }

    get rememberedSidebarState() {
        return localStorage.getItem(SIDEBAR_STATE_KEY)
    }
}
