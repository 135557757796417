import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="dropdown-filter-form"
export default class extends Controller {
  static targets = ["form", "queryField"]

  connect() {
  }

  selectItem(e) {
    const { target } = e
    const { filterKey, filterValue } = target.dataset

    const hiddenField = this.hiddenField(filterKey)

    console.log(filterValue)
    if( hiddenField ) {
      hiddenField.value = filterValue
    }
    else {
      console.error(`Cannot find hidden field by name: ${this.hiddenFieldName(filterKey)}`)
    }

    e.preventDefault()
    this.element.requestSubmit()
  }

  hiddenField(key) {
    return this.queryFieldTargets.filter(field => field.id === this.hiddenFieldId(key))[0]
  }

  hiddenFieldId(key) {
    return `${key}_filter`
  }
}
