import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    connect() {
        clearTimeout(this.fadeInTimeoutId)
        clearTimeout(this.fadeOutTimeoutId)

        this.fadeInTimeoutId = setTimeout(() => {
            this.element.style.top = this.element.scrollHeight
            this.element.classList.add('fade-in')
        }, 10)

        this.fadeOutTimeoutId = setTimeout(() => {
            this.element.classList.add('fade-out')
            setTimeout(() => this.element.remove(), 2500)
        }, 3000)
    }
}
