import {Controller} from "@hotwired/stimulus";
import Editor from "@toast-ui/editor"
import colorSyntax from '@toast-ui/editor-plugin-color-syntax';
import codeSyntaxHighlight from '@toast-ui/editor-plugin-code-syntax-highlight/dist/toastui-editor-plugin-code-syntax-highlight-all.js';
import isMobile from "../lib/is_mobile";

export default class extends Controller {
    static targets = ["content", "stateField"]
    static values = {
        states: Object
    }

    connect() {
        this.#mountEditor()
    }

    async uploadImage(file) {
        const csrfToken = document.querySelector("[name='csrf-token']").content
        let formData = new FormData()
        formData.append("image", file)

        let response = await fetch("/images", {
            headers: {
                "X-CSRF-TOKEN": csrfToken
            },
            method: "post",
            body: formData
        })
        let result = await response.json()

        return result.url
    }

    saveAsDraft(event) {
        event.preventDefault()
        this.stateFieldTarget.value = this.#draftState
        this.element.requestSubmit()
    }

    publish(event) {
        event.preventDefault()
        this.stateFieldTarget.value = this.#publishedState
        this.element.requestSubmit()
    }

    get #draftState() {
        return this.statesValue.draft
    }

    get #publishedState() {
        return this.statesValue.published
    }

    #mountEditor() {
        const initialValue = this.contentTarget.value
        const editorMinHeight = 700 // px
        const otherElementsHeight = 150 // px
        const editorAvailableHeight = window.innerHeight - otherElementsHeight
        let editorHeight
        if ( editorAvailableHeight > editorMinHeight ) {
            editorHeight = editorAvailableHeight
        } else {
            editorHeight = editorMinHeight
        }

        let previewStyle
        if ( isMobile() ) {
            previewStyle = 'tab'
        } else {
            previewStyle = 'vertical'
        }

        this.editor = new Editor({
            el: document.querySelector('#editor'),
            height: `${editorHeight}px`,
            initialEditType: 'markdown',
            initialValue,
            previewStyle,
            theme: 'dark',
            previewHighlight: false,
            autofocus: false,
            plugins: [
                colorSyntax,
                codeSyntaxHighlight
            ],
            events: {
                change: () => {
                    this.contentTarget.value = this.editor.getMarkdown()
                }
            },
            hooks: {
                addImageBlobHook: (fileOrBlob, callback) => {
                    this.uploadImage(fileOrBlob).then(path => callback(path))
                }
            }
        });
    }
}
