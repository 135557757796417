import {Controller} from "@hotwired/stimulus";
import Editor from "@toast-ui/editor"
import codeSyntaxHighlight from '@toast-ui/editor-plugin-code-syntax-highlight/dist/toastui-editor-plugin-code-syntax-highlight-all.js';

export default class extends Controller {
    static targets = ["content"]
    static values = {
        origin: String
    }

    connect() {
        console.log("connected")
        const initialValue = this.originValue
        this.editor = Editor.factory({
            el: document.querySelector('#viewer'),
            viewer: true,
            initialValue,
            plugins: [
                codeSyntaxHighlight
            ]
        });
    }
}
