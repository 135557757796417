import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="dropdown-filter"
export default class extends Controller {
  static targets = ["dropdownMenu", "dropdownButton"]
  static classes = ["active"]
  static values = {
    name: String,
    defaultLabel: String
  }

  selectItem(e) {
    const target = e.target

    // if (this.isSelectDefault(target)) {
    //   this.dropdownButtonTarget.innerHTML = ` ${target.innerHTML} `
    // } else {
    //   this.dropdownButtonTarget.innerHTML = ` ${this.nameValue}： <span class="dropdown-filter-label--selected">${target.innerHTML}</span> `
    // }

    this.removeActiveClassFromOtherItem()
    target.classList.add(this.activeClass)

    e.preventDefault()
  }

  isSelectDefault(el) {
    return el.innerHTML === this.defaultLabelValue
  }

  removeActiveClassFromOtherItem() {
    this.dropdownMenuTarget.querySelectorAll(`.${this.activeClass}`).forEach(el => el.classList.remove(this.activeClass))
  }
}
