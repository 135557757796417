import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="post-publish-widget"
export default class extends Controller {
  static targets = ["publishedAtSetting"]

  connect() {}

  withSpecifyPublishedAt(e) {
    if (e.target.checked) {
      this.specifyPublishedAt()
    }
    else {
      this.cancelSpecifyPublishedAt()
    }
  }

  specifyPublishedAt() {
    this.publishedAtSettingTarget.classList.remove("d-none")
  }

  cancelSpecifyPublishedAt() {
    this.publishedAtSettingTarget.classList.add("d-none")
  }
}

