import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="slide-animation"
export default class extends Controller {
  static values = {
    delay: Number
  }

  connect() {
    if (this.delayValue) {
      setTimeout(() => this.removePullClasses(), this.delayValue)
    }
    else {
      this.removePullClasses()
    }
  }

  removePullClasses () {
    this.element.classList.remove("left-pull")
    this.element.classList.remove("right-pull")
    this.element.classList.remove("right-pull-sm")
    this.element.classList.remove("bottom-pull")
    this.element.classList.remove("top-pull")
  }
}
